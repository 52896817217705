import React, { Component } from "react";
import { Col, Container, Form, Navbar, Row } from "react-bootstrap-v5";
import { toast } from "react-toastify";
import Api from "../../../api/Api";
import { ISetUserProfileDetailsRequest } from "../../../api/api-interfaces/profile/ISetUserProfileDetailsRequest";
import { CHECK_ERROR_MESSAGE } from "../../../constants/Constants";
import AuthHelper from "../../../helpers/auth-helper/AuthHelper";
import { scrollIntoViewHelper } from "../../../helpers/scroll-into-view-helper/scrollIntoViewHelper";
import ValidationErrors from "../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../helpers/validation-helper/Validations";
import RoutingConstants from "../../../routes/RoutingConstants";
import { whiteBorder } from "../../../styles/ButtonStyles";
import IconButton from "../buttons/icon-button/IconButton";
import CustomLink from "../custom-link/CustomLink";
import LoadingBar from "../loading-bar/LoadingBar";
import RequiredField from "../required-field/RequiredField";
import ValidationMessages from "../validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../vertical-space/VerticalSpace";
import { IProfileComponentProps } from "./IProfileComponentProps";
import { IProfileComponentState } from "./IProfileComponentState";
import { scrollToIdHelper } from "../../../helpers/scroll-into-view-helper/scrollToIdHelper";

class ProfileComponent extends Component<IProfileComponentProps, IProfileComponentState> {

  constructor(props: IProfileComponentProps) {
    super(props);

    this.state = {
      contestId: 0,
      page: 1,
      sorting: "",
      firstName: "",
      lastName: "",
      excludeKeys: [],
      isLoading: true,
      validationErrors: {}
    };
  }

  render() {
    return (
      <>
        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <Navbar bg="primary" variant="dark" className='mb-3' id={'kva-profile'}>
                <Container fluid className='flex-wrap'>
                  <div className='d-flex align-items-center me-auto '>
                    <CustomLink to={RoutingConstants.buildEntryListUrl(this.state.contestId, this.state.page, this.state.sorting)}>
                      <IconButton styles={whiteBorder} variant="primary" iconType={"camera"} title={' Gallery'}
                                  className='me-2'/>
                    </CustomLink>
                    <CustomLink to={RoutingConstants.buildSubmitterEntriesUrl(this.state.contestId, 1, this.state.sorting)}>
                      <IconButton styles={whiteBorder} variant="primary" iconType={"list"} title={' My entries'}
                                  className='me-2'/>
                    </CustomLink>
                  </div>

                  <div className='signOut d-flex d-block'>
                    <CustomLink to={RoutingConstants.buildEntryListUrl(this.state.contestId, this.state.page, this.state.sorting)}
                                onClick={this.onSignOutClick.bind(this)}>
                      <IconButton styles={whiteBorder} variant="primary" iconType={"door"} title={' Sign out'}/>
                    </CustomLink>
                  </div>
                </Container>
              </Navbar>

              <h1 className={"text-break headline"}>{"Profile"}</h1>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Form>
                <Row>
                  <Col md={6} lg={5}>
                    <Form.Label>First name<RequiredField/></Form.Label>
                    <Form.Control name="FirstName" type="text" placeholder="Enter first name"
                                  value={this.state.firstName}
                                  onChange={this.onFirstNameChange.bind(this)}
                    />
                    <ValidationMessages fieldName="FirstName" errors={this.state.validationErrors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={6} lg={5}>
                    <Form.Label>Last name<RequiredField/></Form.Label>
                    <Form.Control name="LastName" type="text" placeholder="Enter last name"
                                  value={this.state.lastName}
                                  onChange={this.onLastNameChange.bind(this)}
                    />
                    <ValidationMessages fieldName="LastName" errors={this.state.validationErrors}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <IconButton onClick={this.onSaveProfileData.bind(this)} title="Save" variant={"outline-primary"}
                            styles={{marginRight: "1em"}}/>

                <IconButton onClick={this.onCancel.bind(this)} title="Cancel" variant="outline-secondary"/>
              </Form>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let contestId = this.props.contestId;
    let page = this.props.page;
    let sorting = this.props.sorting;

    this.setState({page: page, contestId: contestId, sorting: sorting});

    await this.getUserProfileDetails();

    scrollToIdHelper(`kva-profile`);
  }

  private async getUserProfileDetails() {
    try {
      let response = await Api.getUserProfileDetails();

      this.setState({
        firstName: response.firstName ? response.firstName : '',
        lastName: response.lastName ? response.lastName : '',
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get user profile details from the server', err)
      );
    }
  }

  private onFirstNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'FirstName');
    this.setState({firstName: e.target.value});
  }

  private onLastNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    Validations.deleteErrors(this.state.validationErrors ?? {}, 'LastName');
    this.setState({lastName: e.target.value});
  }

  private async onSaveProfileData() {
    let errors = this.validateForm();

    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      ProfileComponent.showErrorToastMessage();
      scrollIntoViewHelper(errors);
      return;
    }

    this.setState({isLoading: true});

    let request: ISetUserProfileDetailsRequest = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    };

    try {
      await Api.setUserProfileDetails(request);

      if (this.props.onProfileSaveSuccess) {
        this.props.onProfileSaveSuccess();
      }
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t update user profile on the server', err));
    }
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (!this.state.firstName || this.state.firstName.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'FirstName', ['First name is required']);
    }

    if (!this.state.lastName || this.state.lastName.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'LastName', ['Last name is required']);
    }

    return errors;
  }

  private static showErrorToastMessage() {
    toast.error(CHECK_ERROR_MESSAGE);
  }

  private onCancel() {
    if (this.props.onProfileCancelClick) {
      this.props.onProfileCancelClick();
    }
  }

  onSignOutClick() {
    AuthHelper.removeSession();
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);

    if (this.state.validationErrors) {
      let render = Validations.getValidationSummary(this.state.validationErrors, this.state.excludeKeys);

      if (render) {
        toast.error(render);
      }
    }
  }
}

export default ProfileComponent;