import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { KIWANIS_GOLD_COLOR } from "../../../constants/Constants";
import { ICustomLinkProps } from "./ICustomLinkProps";

class CustomLink extends Component<ICustomLinkProps> {

  render() {
    let styles = this.props.styles;

    return (
      <Link to={this.props.to}
            style={{...styles, color: `${KIWANIS_GOLD_COLOR}`}}
            onClick={this.onClickHandler.bind(this)}
            target={this.props.target}
      >
        {this.props.children}
      </Link>
    );
  }

  private onClickHandler() {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  }
}

export default CustomLink;