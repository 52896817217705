import { faCalendarAlt, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component } from "react";
import { Col, Container, Form, InputGroup, Navbar, Row, Table } from "react-bootstrap-v5";
import Pagination from "react-responsive-pagination";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import { IDeleteRequest } from "../../api/api-interfaces/entry/submitted-entries-list/IDeleteRequest";
import { IListRequest } from "../../api/api-interfaces/entry/submitted-entries-list/IListRequest";
import withVotingAppAuthorize from "../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import CustomLink from "../../components/common/custom-link/CustomLink";
import ImageComponent from "../../components/common/image-component/ImageComponent";
import ImageGallery from "../../components/common/image-gallery/ImageGallery";
import LoadingBar from "../../components/common/loading-bar/LoadingBar";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import { KIWANIS_BLUE_COLOR, KIWANIS_GOLD_COLOR, MOMENT_DATE_TIME_FORMAT } from "../../constants/Constants";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import RoutingConstants from "../../routes/RoutingConstants";
import { whiteBorder } from "../../styles/ButtonStyles";
import DeleteEntryModal from "./delete-entry-modal/DeleteEntryModal";
import styles from './ISubmitterEntries.module.scss';
import { ISubmitterEntriesProps } from "./ISubmitterEntriesProps";
import { ISubmitterEntriesState } from "./ISubmitterEntriesState";
import { scrollToIdHelper } from "../../helpers/scroll-into-view-helper/scrollToIdHelper";
import { faVerticalAlign } from "../../styles/IconStyles";

class SubmitterEntries extends Component<ISubmitterEntriesProps, ISubmitterEntriesState> {
  listener: any;

  constructor(props: ISubmitterEntriesProps) {
    super(props);

    this.state = {
      contestId: 0,
      sorting: '',
      entries: null,

      searchString: '',
      page: 1,
      totalPages: 1,
      isPageClick: false,

      isImageGalleryOpen: false,
      indexOfImages: 0,
      entryImages: [],

      isDeleteEntryDialogOpen: false,
      entryIdToDelete: undefined,
      entryIndexToDelete: undefined,
      entryNameToDelete: undefined,

      redirect: null,
      excludeKeys: [],
      isLoading: true,
      validationErrors: {}
    };
  }

  render() {
    let {entryImages} = this.state;

    if (this.state.redirect !== null) {
      const redirect = this.state.redirect;
      return <Redirect push to={redirect}/>;
    }

    return (
      <ContainerPageSize>
        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <DeleteEntryModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isDeleteEntryDialogOpen}
                entryIdToDelete={this.state.entryIdToDelete}
                entryNameToDelete={this.state.entryNameToDelete}
                onConfirm={this.onConfirmDeleteEntryClick.bind(this)}
                onCancel={this.onCancelDeleteEntryClick.bind(this)}
              />

              <Navbar bg="primary" variant="dark" className='mb-3' id='kva-my-entries'>
                <Container fluid>
                  <div className='d-flex align-items-center me-auto flex-wrap'>
                    <CustomLink to={RoutingConstants.buildEntryListUrl(this.state.contestId, 1, this.state.sorting)}>
                      <IconButton styles={whiteBorder} variant="primary" iconType={"camera"} title={' Gallery'}/>
                    </CustomLink>
                  </div>
                  <div>
                    <CustomLink to={RoutingConstants.buildProfileUrl(this.state.contestId, 1, this.state.sorting)}>
                      <IconButton styles={whiteBorder} iconType={'user'} variant="primary" title={' Profile'}/>
                    </CustomLink>
                  </div>
                </Container>
              </Navbar>

              <h1 className={"text-break headline"}>My entries</h1>

              <Form onSubmit={this.onFilterSubmit.bind(this)}>
                <Row>
                  <Col sm={10} md={8} lg={5} xxl={4}>
                    <InputGroup>
                      <Form.Control type="text" placeholder="Search"
                                    value={this.state.searchString}
                                    onChange={this.onFilterChange.bind(this)}
                      />
                      <IconButton buttonType='submit' variant='outline-primary' title={'Filter'}/>
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <div className={`d-none d-md-block`}>
                <Table striped bordered responsive className={`${styles.columnWith}`}>
                  <thead>
                  <tr>
                    <th style={{width: '220px'}}/>
                    <th>Contest name</th>
                    <th>Entry title</th>
                    <th>Submitted on</th>
                    <th className={'text-center'}/>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.entries && this.state.entries.length > 0
                      ? this.state.entries.map((entry, index) => {
                        return (
                          <tr key={entry.contestEntryId} className="text-break">
                            <td style={{width: '220px'}}>
                              <ImageComponent src={entry.mainImageUrl}
                                              onClick={() => this.setState({
                                                isImageGalleryOpen: true,
                                                entryImages: entry.allImageUrls
                                              })}
                              />
                            </td>
                            <td>
                              <span>{entry.contestName}</span>
                            </td>
                            <td>
                              <span>
                                {
                                  entry.isWinner &&
                                  <span className='me-2'>
                                    <FontAwesomeIcon icon={faTrophy} color={KIWANIS_GOLD_COLOR} style={faVerticalAlign}/>
                                  </span>
                                }

                                {entry.title}
                              </span><br/>
                              {
                                !entry.isApproved
                                  ? <span className="text-secondary"><i>{'<in review>'}</i></span>
                                  : <span className="text-secondary"><i>{'<approved>'}</i></span>
                              }
                            </td>
                            <td>
                            <span title="Submit time">
                              <FontAwesomeIcon icon={faCalendarAlt} color={KIWANIS_BLUE_COLOR} style={faVerticalAlign}/>
                              &nbsp; {this.getFormattedDate(entry.submittedOn)} <br/>
                            </span>
                            </td>
                            <td className='btnColumn' style={{minWidth: '120px'}}>
                            <span className="d-flex flex-wrap gap-1 justify-content-center">
                              {
                                (entry.isApproved && entry.isWinner) &&
                                <Link
                                  to={RoutingConstants.buildWinnerEntryEditUrl(this.state.contestId, this.state.page, entry.contestEntryId, this.state.sorting)}>
                                  <IconButton iconType={'edit'} variant={'outline-primary'} className='me-1'/>
                                </Link>
                              }

                              {
                                (!entry.isApproved) &&
                                <>
                                  <Link
                                    to={RoutingConstants.buildEntryEditUrl(this.state.contestId, this.state.page, entry.contestEntryId, this.state.sorting)}>
                                    <IconButton iconType={'edit'} variant={'outline-primary'} className='me-1'/>
                                  </Link>

                                  <IconButton iconType={'delete'} variant={'outline-danger'} className='me-1'
                                              onClick={() => this.onDeleteEntry(entry.contestEntryId, index, entry.title)}/>
                                </>
                              }

                              {
                                (entry.isApproved) &&
                                <span className="d-flex flex-wrap gap-1 justify-content-center">
                                  <Link
                                    to={RoutingConstants.buildEntryDetailsUrl(this.state.contestId, this.state.page, entry.contestEntryId, this.state.sorting)}>
                                    <IconButton iconType={"eye"} variant={'outline-primary'}/>
                                  </Link>
                                </span>
                              }
                              </span>
                            </td>
                          </tr>
                        );
                      })
                      : <tr>
                        <td colSpan={6} className={'text-center'}>No contest entries</td>
                      </tr>
                  }
                  </tbody>
                </Table>
              </div>

              {
                this.state.entries && this.state.entries.length > 0
                  ? this.state.entries.map((entry, index) => {
                    return (
                      <div key={entry.contestEntryId} className='d-block d-md-none text-break'>
                        <Row>
                          <Col sm={8}>
                            <ImageComponent src={entry.mainImageUrl}
                                            onClick={() => this.setState({
                                              isImageGalleryOpen: true,
                                              entryImages: entry.allImageUrls
                                            })}
                            />
                          </Col>
                        </Row>

                        <div>{entry.contestName}</div>
                        <div className={styles.value}>
                          {
                            entry.isWinner &&
                            <span className='me-2'>
                              <FontAwesomeIcon icon={faTrophy} color={KIWANIS_GOLD_COLOR} style={faVerticalAlign}/>
                            </span>
                          }
                          {entry.title}
                        </div>

                        <div>
                          {
                            !entry.isApproved
                              ? <span className="text-secondary"><i>{'<in review>'}</i></span>
                              : <span className="text-secondary"><i>{'<approved>'}</i></span>
                          }
                        </div>

                        <div>
                          <span title="Submit time">
                            <FontAwesomeIcon icon={faCalendarAlt} color={KIWANIS_BLUE_COLOR} style={faVerticalAlign}/>
                            &nbsp; {this.getFormattedDate(entry.submittedOn)} <br/>
                          </span>
                        </div>

                        <Row>
                          <Col>
                            <span className="d-flex flex-wrap gap-1 justify-content-start my-2">
                              {
                                (entry.isApproved && entry.isWinner) &&
                                <Link
                                  to={RoutingConstants.buildWinnerEntryEditUrl(this.state.contestId, this.state.page, entry.contestEntryId, this.state.sorting)}>
                                  <IconButton iconType={'edit'} variant={'outline-primary'} className='me-1'/>
                                </Link>
                              }

                              {
                                (!entry.isApproved) &&
                                <>
                                  <Link
                                    to={RoutingConstants.buildEntryEditUrl(this.state.contestId, this.state.page, entry.contestEntryId, this.state.sorting)}>
                                    <IconButton iconType={'edit'} variant={'outline-primary'} className='me-1'/>
                                  </Link>

                                  <IconButton iconType={'delete'} variant={'outline-danger'} className='me-1'
                                              onClick={() => this.onDeleteEntry(entry.contestEntryId, index, entry.title)}/>
                                </>
                              }

                              {
                                (entry.isApproved) &&
                                <span className="d-flex flex-wrap gap-1 justify-content-center">
                                  <Link
                                    to={RoutingConstants.buildEntryDetailsUrl(this.state.contestId, this.state.page, entry.contestEntryId, this.state.sorting)}>
                                    <IconButton iconType={"eye"} variant={'outline-primary'} className='me-1'/>
                                  </Link>
                                </span>
                              }
                              </span>
                          </Col>
                        </Row>

                        <hr/>
                      </div>
                    );
                  })
                  : <div className={'d-block d-md-none text-center'}>No contest entries</div>
              }

              <ImageGallery
                isOpen={this.state.isImageGalleryOpen}
                indexOfImages={this.state.indexOfImages}
                images={entryImages}
                onCloseRequest={() => this.setState({isImageGalleryOpen: false})}
                onMovePrevRequest={() =>
                  this.setState({indexOfImages: (this.state.indexOfImages + entryImages.length - 1) % entryImages.length})
                }

                onMoveNextRequest={() =>
                  this.setState({indexOfImages: (this.state.indexOfImages + entryImages.length + 1) % entryImages.length})
                }
              />

              {
                this.state.totalPages > 1 &&
                <Row id='kva-submitter-entries-pager'>
                  <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className='mt-2'>
                    <Pagination current={this.state.page}
                                total={this.state.totalPages}
                                onPageChange={(page: number) => this.onSubmittedEntryPageChange(page)}
                    />
                  </Col>
                </Row>
              }
            </>
        }
      </ContainerPageSize>
    );
  }

  getFormattedDate(submittedOn: string) {
    return moment(submittedOn).format(MOMENT_DATE_TIME_FORMAT);
  }


  async componentDidMount() {
    let contestId = this.props.contestId;
    let page = this.props.page;
    let sorting = this.props.sorting;

    this.setState({contestId: contestId, page: page, sorting: sorting});

    await this.getListOfSubmittedEntries(page);

    scrollToIdHelper('kva-my-entries')

    this.listener = this.props.history.listen( (location, action) => {
      if (action === "PUSH" && this.state.isPageClick) {
        this.scrollToPager();

        this.setState({isPageClick: false});
      }
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  async componentDidUpdate(prevProps: Readonly<ISubmitterEntriesProps>) {
    if (this.props.page !== prevProps.page) {
      let state = {...this.state};
      state.isLoading = true;
      state.redirect = null;
      this.setState(state);

      await this.getListOfSubmittedEntries(this.props.page);
    }
  }


  private onFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({searchString: event.target.value});
  }


  private async onFilterSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    this.setState({isLoading: true});
    await this.getListOfSubmittedEntries(1);
  }


  private async getListOfSubmittedEntries(page: number) {
    let request: IListRequest = {
      searchString: this.state.searchString,
      page: page
    };

    try {
      let response = await Api.getListOfSubmittedEntries(request);

      this.setState({
        entries: response.entries,
        page: response.pager.page,
        totalPages: response.pager.totalPages,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get submitted entries from the server', err)
      );
    }
  }


  private async onSubmittedEntryPageChange(page: number) {
    if (this.state.page !== page) {
      this.setState({
        page: page,
        isPageClick: true,
        redirect: RoutingConstants.buildSubmitterEntriesUrl(this.state.contestId, page, this.state.sorting)
      });
    }
  }


  private async onDeleteEntry(contestEntryId: number, index: number, entryName: string) {
    let state = {...this.state};
    state.entryNameToDelete = entryName;
    state.entryIdToDelete = contestEntryId;
    state.entryIndexToDelete = index;
    state.isDeleteEntryDialogOpen = true;
    this.setState(state);
  }


  private async onConfirmDeleteEntryClick(entryId: number) {
    this.setState({isLoading: true});

    let request: IDeleteRequest = {
      entryId: entryId,
      page: this.state.page
    };

    try {
      let response = await Api.deleteEntry(request);

      let state = {...this.state};
      state.entries = response.entries;
      state.page = response.pager.page;
      state.totalPages = response.pager.totalPages;
      state.isLoading = false;
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete entry', err));

      this.closeDeleteEntryDialog();
      return;
    }
    this.closeDeleteEntryDialog();
  }


  private onCancelDeleteEntryClick() {
    this.closeDeleteEntryDialog();
  }


  private closeDeleteEntryDialog() {
    let state = {...this.state};
    state.entryIdToDelete = undefined;
    state.entryNameToDelete = undefined;
    state.isDeleteEntryDialogOpen = false;
    this.setState(state);
  }


  private scrollToPager() {
    setTimeout(() => {
      scrollToIdHelper(`kva-submitter-entries-pager`);
    }, 500);
  }


  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);

    if (this.state.validationErrors) {
      let render = Validations.getValidationSummary(this.state.validationErrors, this.state.excludeKeys);

      if (render) {
        toast.error(render);
      }
    }
  }
}

export default withVotingAppAuthorize(SubmitterEntries);