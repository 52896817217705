import React from 'react';
import { Col, Row } from "react-bootstrap-v5";
import Countdown, { CountdownRendererFn, zeroPad } from "react-countdown";
import styles from './CountdownTimer.module.scss';
import { ICountdownTimerProps } from './ICountdownTimerProps';

const CountdownTimer = (props: ICountdownTimerProps) => {
  const renderer: CountdownRendererFn = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Row>
          <Col className={styles.contestEnded}>Contest Ended</Col>
        </Row>
      );
    } else {
      return (
        <div className={styles.countdownWrapper}>
          <div className={styles.countdownBox}>
            {zeroPad(days)}
            <span className={styles.legend}>Days</span>
          </div>
          <div className={styles.countdownBox}>
            {zeroPad(hours)}
            <span className={styles.legend}>Hours</span>
          </div>
          <div className={styles.countdownBox}>
            {zeroPad(minutes)}
            <span className={styles.legend}>Minutes</span>
          </div>
          <div className={styles.countdownBox}>
            {zeroPad(seconds)}
            <span className={styles.legend}>Seconds</span>
          </div>
        </div>
      )
    }
  };

  return (
    <>
      {
        (props.isVotingOpen && props.date !== null) || props.isVotingEnded
          ? <Countdown date={props.date ?? Date.now()} renderer={renderer}/>
          : null
      }
    </>
  );
};

export default CountdownTimer;