import React from "react";
import { Col, Row } from "react-bootstrap-v5";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import { IEntryDoesNotExistProps } from "./IEntryDoesNotExistProps";
import CustomLink from "../../components/common/custom-link/CustomLink";
import RoutingConstants from "../../routes/RoutingConstants";
import { whiteBorder } from "../../styles/ButtonStyles";

const EntryDoesNotExits: React.FC<IEntryDoesNotExistProps> = (props: IEntryDoesNotExistProps) => {
  return (
    <ContainerPageSize>
      <VerticalSpace size={VerticalSpaceSize.large}/>
      <div className="text-center mt-5">
        <Row>
          <Col md={{span: 8, offset: 2}}>
            <h1 className={"headline"}>Contest entry can not be found</h1>
            <CustomLink to={RoutingConstants.buildEntryListUrl(props.contestId, props.page, props.sorting)}>
              <IconButton styles={whiteBorder} variant="primary" iconType={"camera"} title={' Gallery'}/>
            </CustomLink>
          </Col>
        </Row>
      </div>
    </ContainerPageSize>
  );
};

export default EntryDoesNotExits;