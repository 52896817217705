import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import './App.scss';
import Authorize from "./components/common/authorize/Authorize";
import Toast from "./components/common/toast/Toast";
import IAppProps from "./IAppProps";
import AddOrEditEntry from "./pages/add-or-edit-entry/AddOrEditEntry";
import EditWinnerEntry from "./pages/edit-entry-after-win/EditEntryAfterWin";
import EntryDetails from "./pages/entry-details/EntryDetails";
import EntryList from "./pages/entry-list/EntryList";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import NoPermitted from "./pages/no-permitted/NoPermitted";
import NotFoundPage from "./pages/not-found/NotFound";
import ProfilePage from "./pages/profile-page/ProfilePage";
import SubmitterEntries from "./pages/submitter-entries/SubmitterEntries";
import RoutingConstants from "./routes/RoutingConstants";
import ContestDoesNotExist from "./pages/contest-does-not-exist/ContestDoesNotExist";
import EntryDoesNotExits from "./pages/entry-does-not-exist/EntryDoesNotExits";
import ContestDetails from "./components/common/contest-details/ContestDetails";
import IAppState from "./IAppState";
import ContestList from "./pages/contest-list/ContestList";
import PastContestList from "./pages/past-contest-list/PastContestList";
import { ENTRY_SORTING } from "./constants/Constants";

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);

    this.state = {
      contestDetailsData: null
    };
  }

  render() {
    return (
      <>
        <Toast/>

        <Switch>
          <Route exact path={RoutingConstants.LOGIN}>
            <Login/>
          </Route>

          <Route exact path={RoutingConstants.LOGIN_WITH_RETURN_URL} render={
            ({match}) => (
              <Login returnUrl={match.params.returnUrl === undefined ? undefined : atob(match.params.returnUrl)}/>
            )
          }/>

          <Route exact path={RoutingConstants.HOME}>
            {
              (this.props.startingView === "contest" && this.props.contestId !== undefined && this.props.contestId !== null)
                ? <Redirect to={RoutingConstants.buildEntryListUrl(this.props.contestId, 1, ENTRY_SORTING.Random)}/>
                : this.props.startingView === "all-contests-list"
                  ? <Redirect to={RoutingConstants.buildContestListUrl(1)} />
                  : this.props.startingView === "past-contests-list"
                    ? <Redirect to={RoutingConstants.buildPastContestListUrl(1)} />
                    : <Home/>
            }
          </Route>

          <Route exact path={RoutingConstants.CONTEST_LIST} render={
            ({match, history}) => (
              <ContestList page={match.params.page ? parseInt(match.params.page) : 1}
                           itemsPerPage={match.params.itemsPerPage}
                           history={history}
              />
            )
          }/>

          <Route exact path={RoutingConstants.PAST_CONTEST_LIST} render={
            ({match, history}) => (
              <PastContestList page={match.params.page ? parseInt(match.params.page) : 1}
                           itemsPerPage={match.params.itemsPerPage}
                           history={history}
              />
            )
          }/>

          <Route exact path={RoutingConstants.ENTRY_LIST} render={
            ({match, history}) => (
              <>
                <ContestDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                                contestDetailsData={this.state.contestDetailsData}
                                dataUpdateCallback={data => this.setState({contestDetailsData: data})}
                />
                <EntryList contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}
                           page={match.params.page ? parseInt(match.params.page) : 1}
                           sorting={match.params.sorting ? match.params.sorting  : null}
                           history={history}
                />
              </>
            )
          }/>

          <Route exact path={RoutingConstants.ENTRY_DETAILS} render={
            ({match, history}) => (
              <>
                <ContestDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                                contestDetailsData={this.state.contestDetailsData}
                                dataUpdateCallback={data => this.setState({contestDetailsData: data})}
                />
                <EntryDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}
                              page={match.params.page ? parseInt(match.params.page) : 1}
                              entryId={match.params.entryId ? parseInt(match.params.entryId) : 0}
                              sorting={match.params.sorting ? match.params.sorting  : ENTRY_SORTING.Random}
                              history={history}
                />
              </>
            )
          }/>

          <Route exact path={RoutingConstants.CREATE_ENTRY || RoutingConstants.EDIT_ENTRY} render={
            ({match}) => (
              <Authorize redirectUrl={match.url}>
                <ContestDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                                contestDetailsData={this.state.contestDetailsData}
                                dataUpdateCallback={data => this.setState({contestDetailsData: data})}
                />
                <AddOrEditEntry contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}
                                page={match.params.page ? parseInt(match.params.page) : 1}
                                entryId={match.params.entryId ? parseInt(match.params.entryId) : null}
                                sorting={match.params.sorting ? match.params.sorting  : ENTRY_SORTING.Random}
                />
              </Authorize>
            )
          }/>

          <Route exact path={RoutingConstants.EDIT_ENTRY} render={
            ({match}) => (
              <Authorize redirectUrl={match.url}>
                <ContestDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                                contestDetailsData={this.state.contestDetailsData}
                                dataUpdateCallback={data => this.setState({contestDetailsData: data})}
                />
                <AddOrEditEntry contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}
                                page={match.params.page ? parseInt(match.params.page) : 1}
                                entryId={match.params.entryId ? parseInt(match.params.entryId) : null}
                                sorting={match.params.sorting ? match.params.sorting  : ENTRY_SORTING.Random}
                />
              </Authorize>
            )
          }/>

          <Route exact path={RoutingConstants.PROFILE} render={
            ({match}) => (
              <Authorize redirectUrl={match.url}>
                <ContestDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                                contestDetailsData={this.state.contestDetailsData}
                                dataUpdateCallback={data => this.setState({contestDetailsData: data})}
                />
                <ProfilePage contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}
                             page={match.params.page ? parseInt(match.params.page) : 1}
                             sorting={match.params.sorting ? match.params.sorting  : ENTRY_SORTING.Random}
                />
              </Authorize>
            )
          }/>

          <Route exact path={RoutingConstants.SUBMITTER_ENTRIES} render={
            ({match, history}) => (
              <Authorize redirectUrl={match.url}>
                <ContestDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                                contestDetailsData={this.state.contestDetailsData}
                                dataUpdateCallback={data => this.setState({contestDetailsData: data})}
                />
                <SubmitterEntries contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}
                                  page={match.params.page ? parseInt(match.params.page) : 1}
                                  sorting={match.params.sorting ? match.params.sorting  : ENTRY_SORTING.Random}
                                  history={history}
                />
              </Authorize>
            )
          }/>

          <Route exact path={RoutingConstants.EDIT_ENTRY_AFTER_WIN} render={
            ({match}) => (
              <Authorize redirectUrl={match.url}>
                <ContestDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                                contestDetailsData={this.state.contestDetailsData}
                                dataUpdateCallback={data => this.setState({contestDetailsData: data})}
                />
                <EditWinnerEntry contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}
                                 page={match.params.page ? parseInt(match.params.page) : 1}
                                 entryId={match.params.entryId ? parseInt(match.params.entryId) : 0}
                                 sorting={match.params.sorting ? match.params.sorting  : ENTRY_SORTING.Random}
                />
              </Authorize>
            )
          }/>

          <Route path={RoutingConstants.NO_PERMITTED}>
            <NoPermitted/>
          </Route>

          <Route path={RoutingConstants.CONTEST_DOES_NOT_EXIST}>
            <ContestDoesNotExist/>
          </Route>

          <Route exact path={RoutingConstants.ENTRY_DOES_NOT_EXIST} render={
            ({match}) => (
              <>
                <ContestDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                                contestDetailsData={this.state.contestDetailsData}
                                dataUpdateCallback={data => this.setState({contestDetailsData: data})}
                />
                <EntryDoesNotExits contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}
                                   page={match.params.page ? parseInt(match.params.page) : 1}
                                   sorting={match.params.sorting ? match.params.sorting  : ENTRY_SORTING.Random}
                />
              </>
            )
          }/>

          <Route path="*">
            <NotFoundPage/>
          </Route>

        </Switch>
      </>
    );
  }
}

export default App;
