import React, { Component } from 'react';
import { Container } from "react-bootstrap-v5";

export default class ContainerPageSize extends Component {
  render() {
    return (
      <Container fluid className='pt-3 pb-5'>
        {this.props.children}
      </Container>
    );
  }
}
