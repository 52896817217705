import React, { Component } from 'react';
import { Col, Form, FormCheck, Row } from "react-bootstrap-v5";
import FormDescription from "../../../../components/common/form-description/FormDescription";
import ValidationMessages from "../../../../components/common/validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../components/common/vertical-space/VerticalSpace";
import { DEFAULT } from '../../../../constants/Constants';
import FieldNameHelper from "../../../../helpers/field-name-helper/FieldNameHelper";
import { IRadioButtonFieldProps } from "./IRadioButtonFieldProps";
import { IRadioButtonFieldState } from "./IRadioButtonFieldState";

class RadioButtonField extends Component<IRadioButtonFieldProps, IRadioButtonFieldState> {

  constructor(props: IRadioButtonFieldProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {field, index, errors} = this.props;
    const radioButtonFieldName = FieldNameHelper.getRadioButtonFieldName(index);
    const stringValueFieldName = FieldNameHelper.getStringValueFieldName(index);
    const fieldValuesStringValueFieldName = FieldNameHelper.getFieldValuesStringValueFieldName(index);

    return (
      <>
        <Row>
          <Col md={6} lg={5}>
            <Form.Label className='text-break'>
              {field.name}
              {field.isRequired ? <span className="required-asterisk">*</span> : null}
            </Form.Label>
            {
              field.options?.map((option, i) => {
                return (
                  <div className='d-flex' key={'rb_' + field.id + i}>
                    <FormCheck.Label htmlFor={FieldNameHelper.getOptionsFieldName(index, i)} className='mx-2'>
                      <Form.Check type='radio'
                                  id={FieldNameHelper.getOptionsFieldName(index, i)}
                                  name={radioButtonFieldName}
                                  value={field.value}
                                  checked={option === field.value}
                                  onChange={() => this.onRadioButtonFieldChange(option)}
                                  label={option}
                      />
                    </FormCheck.Label>
                  </div>
                );
              })
            }
            {
              !field.isRequired && field.value &&
              <div className='d-flex'>
                <FormCheck.Label htmlFor={`rb_${index}` + DEFAULT} className='mx-2'>
                  <Form.Check id={`rb_${index}` + DEFAULT}
                              type='radio'
                              value={DEFAULT}
                              checked={DEFAULT === field.value}
                              onChange={() => this.onRadioButtonFieldChange(DEFAULT)}
                              label={'Not selected'}
                  />
                </FormCheck.Label>
              </div>
            }
            <FormDescription formText={field.description}/>
            <ValidationMessages fieldName={radioButtonFieldName} errors={errors}/>
            <ValidationMessages fieldName={stringValueFieldName} errors={errors}/>
            <ValidationMessages fieldName={fieldValuesStringValueFieldName} errors={errors}/>
          </Col>
        </Row>
        <VerticalSpace size={VerticalSpaceSize.small}/>
      </>
    );
  }

  onRadioButtonFieldChange(value: string) {
    if (this.props.onRadioButtonFieldChange) {
      this.props.onRadioButtonFieldChange(value);
    }
  }
}

export default RadioButtonField;