import moment from "moment";
import React, { Component } from 'react';
import { Col, Container, Form, Navbar, Row } from "react-bootstrap-v5";
import { Redirect } from 'react-router-dom';
import { toast } from "react-toastify";
import Api from "../../api/Api";
import { ICustomFieldDefinition } from "../../api/api-interfaces/entry/add-or-edit-entry/ICustomFieldDefinition";
import { IEntryFieldWithIndexInfo } from "../../api/api-interfaces/entry/edit-entry-after-win/IEntryFieldWithIndexInfo";
import { IEntryFieldInfo } from "../../api/api-interfaces/entry/IEntryFieldInfo";
import { IEditableFieldInfo } from "../../api/api-interfaces/entry/submitted-entries-list/IEditableFieldInfo";
import {
  IEditableOrReadonlyFieldInfo
} from "../../api/api-interfaces/entry/submitted-entries-list/IEditableOrReadonlyFieldInfo";
import { ISaveRequest } from "../../api/api-interfaces/entry/edit-entry-after-win/ISaveRequest";
import withVotingAppAuthorize from "../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import CustomLink from "../../components/common/custom-link/CustomLink";
import FormDescription from "../../components/common/form-description/FormDescription";
import ImageComponent from "../../components/common/image-component/ImageComponent";
import ImageGallery from "../../components/common/image-gallery/ImageGallery";
import LoadingBar from "../../components/common/loading-bar/LoadingBar";
import NotProvided from "../../components/common/not-provided-value/NotProvided";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import {
  CHECK_ERROR_MESSAGE,
  CUSTOM_FIELD_CODES,
  DATE_TIME_ISO_FORMAT,
  DEFAULT,
  MOMENT_DATE_TIME_FORMAT
} from "../../constants/Constants";
import AuthHelper from "../../helpers/auth-helper/AuthHelper";
import FieldNameHelper from "../../helpers/field-name-helper/FieldNameHelper";
import { scrollIntoViewHelper } from "../../helpers/scroll-into-view-helper/scrollIntoViewHelper";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import RoutingConstants from "../../routes/RoutingConstants";
import { whiteBorder } from "../../styles/ButtonStyles";
import { img, thumb, thumbInner, thumbsContainer } from "../../styles/ImageSmallStyles";
import CheckboxField from "../add-or-edit-entry/custom-fields/checkbox/CheckboxField";
import DateTimePickerField from "../add-or-edit-entry/custom-fields/datepicker/DateTimePickerField";
import DropdownField from "../add-or-edit-entry/custom-fields/dropdown/DropdownField";
import RadioButtonField from "../add-or-edit-entry/custom-fields/radio-button/RadioButtonField";
import TextField from "../add-or-edit-entry/custom-fields/text/TextField";
import TextareaField from "../add-or-edit-entry/custom-fields/textarea/TextareaField";
import { IEditEntryAfterWinProps } from "./IEditEntryAfterWinProps";
import { IEditEntryAfterWinState } from "./IEditEntryAfterWinState";
import { scrollToIdHelper } from "../../helpers/scroll-into-view-helper/scrollToIdHelper";

class EditEntryAfterWin extends Component<IEditEntryAfterWinProps, IEditEntryAfterWinState> {

  constructor(props: IEditEntryAfterWinProps) {
    super(props);

    this.state = {
      contestId: 0,
      page: 1,
      entryId: 0,
      sorting: '',

      title: '',
      description: '',
      defaultImageUrl: '',
      imageUrls: [],
      customFields: [],

      isImageGalleryOpen: false,
      indexOfImages: 0,

      excludeKeys: [],
      redirect: null,
      validationErrors: null,
      isLoading: true
    };
  }

  render() {
    let {imageUrls} = this.state;

    const errors = this.state.validationErrors ?? {};

    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    let profileUrl = AuthHelper.isTokenSet()
      ? RoutingConstants.buildProfileUrl(this.state.contestId, this.state.page, this.state.sorting)
      : RoutingConstants.buildLoginRedirectUrl(RoutingConstants.buildProfileUrl(this.state.contestId, this.state.page, this.state.sorting));

    return (
      <ContainerPageSize>
        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <Navbar bg="primary" variant="dark" className='mb-3' id='kva-edit-after-win'>
                <Container fluid>
                  <div className='d-flex align-items-center me-auto flex-wrap'>
                    <CustomLink to={RoutingConstants.buildEntryListUrl(this.state.contestId, this.state.page, this.state.sorting)}>
                      <IconButton styles={whiteBorder} variant="primary" iconType={"camera"} title={' Gallery'}/>
                    </CustomLink>
                  </div>
                  <div>
                    <CustomLink to={profileUrl}>
                      <IconButton styles={whiteBorder} iconType={'user'} variant="primary" title={' Profile'}/>
                    </CustomLink>
                  </div>
                </Container>
              </Navbar>

              <h1 className={"text-break headline"}>{"Edit entry"}</h1>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Row>
                <Col md={6} lg={5}>
                  <Form.Label className='mb-0'>Title</Form.Label>
                  <div className='text-break'>{this.state.title}</div>
                </Col>
              </Row>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Row>
                <Col md={6} lg={5}>
                  <Form.Label className='mb-0'>Images</Form.Label>
                  <ImageComponent src={this.state.defaultImageUrl}
                                  onClick={() => this.setState({isImageGalleryOpen: true, indexOfImages: 0})}
                  />

                  {
                    imageUrls && imageUrls.length > 1 &&
                    <aside style={thumbsContainer} className='mt-2'>
                      {
                        imageUrls.map((url, index) => {
                          return (
                            <div style={thumb} key={index}>
                              <div style={thumbInner}>
                                <img style={img} src={url} alt={'entry img'} className='cursor_pointer'
                                     onClick={() => this.setState({isImageGalleryOpen: true, indexOfImages: index})}/>
                              </div>
                            </div>
                          );
                        })
                      }
                    </aside>
                  }
                </Col>

                <ImageGallery
                  isOpen={this.state.isImageGalleryOpen}
                  indexOfImages={this.state.indexOfImages}
                  images={imageUrls}
                  onCloseRequest={() => this.setState({isImageGalleryOpen: false})}
                  onMovePrevRequest={() =>
                    this.setState({indexOfImages: (this.state.indexOfImages + imageUrls.length - 1) % imageUrls.length})
                  }
                  onMoveNextRequest={() =>
                    this.setState({indexOfImages: (this.state.indexOfImages + imageUrls.length + 1) % imageUrls.length})
                  }
                />
              </Row>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Row>
                <Col md={6} lg={5}>
                  <Form.Label>Description</Form.Label>
                  <div className='text-break'>{this.state.description}</div>
                </Col>
              </Row>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Form>
                {
                  this.state.customFields.map((field, index) => {
                    if (field.readonlyField) {
                      switch (field.readonlyField.typeCode) {
                        case CUSTOM_FIELD_CODES.Text:
                        case CUSTOM_FIELD_CODES.TextArea:
                        case CUSTOM_FIELD_CODES.RadioButton:
                        case CUSTOM_FIELD_CODES.DropDownList:
                          return (
                            <div key={index} className='text-break'>
                              <Row>
                                <Col md={6} lg={5}>
                                  <Form.Label className='mb-0'>{field.readonlyField.name}</Form.Label>
                                  <div>{field.readonlyField.stringValue}</div>
                                  <FormDescription formText={field.readonlyField.description}/>
                                </Col>
                              </Row>
                              <VerticalSpace size={VerticalSpaceSize.small}/>
                            </div>
                          );
                        case CUSTOM_FIELD_CODES.Checkbox:
                          return (
                            <div key={index} className='text-break'>
                              <Row>
                                <Col md={6} lg={5}>
                                  <Form.Label className='mb-0'>{field.readonlyField.name}</Form.Label>
                                  <div>
                                    {
                                      field.readonlyField.boolValue === null
                                        ? <NotProvided/>
                                        : field.readonlyField.boolValue ? 'Yes' : 'No'
                                    }
                                  </div>
                                  <FormDescription formText={field.readonlyField.description}/>
                                </Col>
                              </Row>
                              <VerticalSpace size={VerticalSpaceSize.small}/>
                            </div>
                          );
                        case CUSTOM_FIELD_CODES.DatePicker:
                          return (
                            <div key={index} className='text-break'>
                              <Row>
                                <Col md={6} lg={5}>
                                  <Form.Label className='mb-0'>{field.readonlyField.name}</Form.Label>
                                  <div>
                                    {
                                      field.readonlyField.dateTimeValue
                                        ? moment(field.readonlyField.dateTimeValue).format(MOMENT_DATE_TIME_FORMAT)
                                        : <NotProvided/>
                                    }
                                  </div>
                                  <FormDescription formText={field.readonlyField.description}/>
                                </Col>
                              </Row>
                              <VerticalSpace size={VerticalSpaceSize.small}/>
                            </div>
                          );
                        default:
                          return (
                            <div>
                              <span>Invalid field</span>
                            </div>
                          );
                      }
                    } else {
                      switch (field.editableField.fieldTypeCode) {
                        case CUSTOM_FIELD_CODES.Text:
                          return (
                            <TextField key={field.editableField.contestCustomFieldId} index={index} errors={errors}
                                       field={this.convertIEditableFieldInfoToICustomFieldDefinition(field.editableField)}
                                       onTextChange={value => this.onTextFieldChange(value, index)}
                            />
                          );
                        case CUSTOM_FIELD_CODES.TextArea:
                          return (
                            <TextareaField key={field.editableField.contestCustomFieldId} index={index} errors={errors}
                                           field={this.convertIEditableFieldInfoToICustomFieldDefinition(field.editableField)}
                                           onTextareaChange={value => this.onTextareaFieldChange(value, index)}
                            />
                          );
                        case CUSTOM_FIELD_CODES.RadioButton:
                          return (
                            <RadioButtonField key={field.editableField.contestCustomFieldId} index={index}
                                              errors={errors}
                                              field={this.convertIEditableFieldInfoToICustomFieldDefinition(field.editableField)}
                                              onRadioButtonFieldChange={value => this.onRadioButtonFieldChange(value, index)}
                            />
                          );
                        case CUSTOM_FIELD_CODES.DropDownList:
                          return (
                            <DropdownField key={field.editableField.contestCustomFieldId} index={index} errors={errors}
                                           field={this.convertIEditableFieldInfoToICustomFieldDefinition(field.editableField)}
                                           onDropdownFieldChange={value => this.onDropdownFieldChange(value, index)}
                            />
                          );
                        case CUSTOM_FIELD_CODES.Checkbox:
                          return (
                            <CheckboxField key={field.editableField.contestCustomFieldId} index={index} errors={errors}
                                           field={this.convertIEditableFieldInfoToICustomFieldDefinition(field.editableField)}
                                           onCheckboxFieldChange={value => this.onCheckboxFieldChange(value, index)}
                            />
                          );
                        case CUSTOM_FIELD_CODES.DatePicker:
                          return (
                            <DateTimePickerField key={field.editableField.contestCustomFieldId} index={index}
                                                 errors={errors}
                                                 field={this.convertIEditableFieldInfoToICustomFieldDefinition(field.editableField)}
                                                 onDateClick={date => this.onDateFieldChange(date, index)}
                            />
                          );
                        default:
                          return (
                            <div key={field.editableField.contestCustomFieldId}>
                              <span>Invalid field</span>
                            </div>
                          );
                      }
                    }
                  })
                }

                <IconButton onClick={this.onSaveEntry.bind(this)} title="Save" variant={"outline-primary"}
                            styles={{marginRight: "1em"}}/>

                <IconButton onClick={this.onCancel.bind(this)} title="Cancel" variant="outline-secondary"/>
              </Form>
            </>
        }
      </ContainerPageSize>
    );
  }

  async componentDidMount() {
    let contestId = this.props.contestId;
    let page = this.props.page;
    let entryId = this.props.entryId;
    let sorting = this.props.sorting;

    this.setState({contestId: contestId, page: page, entryId: entryId, sorting: sorting});

    await this.getDetailsForEditingAfterWin(entryId);

    scrollToIdHelper('kva-edit-after-win')
  }

  private async getDetailsForEditingAfterWin(entryId: number) {
    try {
      let response = await Api.getDetailsForEditingAfterWin(entryId);

      let state = {...this.state};

      let excludeKeys = this.addFieldForEditingToExcludeKeys(response.fields);
      state.excludeKeys = [...state.excludeKeys, ...excludeKeys];

      state.title = response.title;
      state.description = response.description;
      state.defaultImageUrl = response.defaultImageUrl;
      state.imageUrls = response.imageUrls;
      state.customFields = response.fields;

      state.isLoading = false;
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get details for editing', err)
      );
    }
  }

  private onTextFieldChange(value: string, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getTextFieldName(index));
    state.customFields[index].editableField.stringValue = value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onTextareaFieldChange(value: string, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getTextareaFieldName(index));
    state.customFields[index].editableField.stringValue = value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onRadioButtonFieldChange(value: string, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getRadioButtonFieldName(index));
    state.customFields[index].editableField.stringValue = value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onDropdownFieldChange(value: string, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getDropdownFieldName(index));
    state.customFields[index].editableField.stringValue = value === DEFAULT ? null : value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onCheckboxFieldChange(value: boolean, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getCheckboxFieldName(index));
    state.customFields[index].editableField.boolValue = value;
    state.validationErrors = errors;
    this.setState(state);
  }

  private onDateFieldChange(date: Date, index: number) {
    let state = {...this.state};
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, FieldNameHelper.getDatePickerFieldName(index));
    state.customFields[index].editableField.dateTimeValue = date;
    state.validationErrors = errors;
    this.setState(state);
  }

  private async onSaveEntry() {
    let errors = this.validateForm();

    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      EditEntryAfterWin.showErrorToastMessage();
      scrollIntoViewHelper(errors);
      return;
    }

    this.setState({isLoading: true});

    let fields: IEntryFieldWithIndexInfo[] =
      this.convertIEditableOrReadonlyFieldInfoToIEntryFieldWithIndexInfo(this.state.customFields);

    let request: ISaveRequest = {
      contestEntryId: this.state.entryId,
      fieldValues: fields,
    };

    try {
      await Api.saveEditedEntryAfterWin(request);

      let state = {...this.state};
      if (state.entryId) {
        state.redirect = RoutingConstants.buildSubmitterEntriesUrl(this.state.contestId, this.state.page, this.state.sorting);
      } else {
        state.isLoading = false;
      }
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t update entry after win', err));
    }
  }

  private onCancel() {
    let state = {...this.state};
    let redirect = state.entryId
      ? RoutingConstants.buildSubmitterEntriesUrl(this.state.contestId, this.state.page, this.state.sorting)
      : RoutingConstants.buildEntryListUrl(this.state.contestId, this.state.page, this.state.sorting);
    state.redirect = redirect;
    this.setState(state);
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    this.state.customFields.forEach((x, index) => {
      if (x.editableField && x.editableField.isRequired) {
        switch (x.editableField.fieldTypeCode) {
          case CUSTOM_FIELD_CODES.Text:
            if (!x.editableField.stringValue || x.editableField.stringValue.trim().length < 1) {
              errors = Validations.setErrors(
                {...errors}, FieldNameHelper.getTextFieldName(index),
                [`${x.editableField.name} is required`]
              );

              let state = {...this.state};
              if (!this.state.excludeKeys.includes(FieldNameHelper.getTextFieldName(index))) {
                state.excludeKeys.push(FieldNameHelper.getTextFieldName(index));
              }
              this.setState(state);
            }
            break;
          case CUSTOM_FIELD_CODES.TextArea:
            if (!x.editableField.stringValue || x.editableField.stringValue.trim().length < 1) {
              errors = Validations.setErrors(
                {...errors}, FieldNameHelper.getTextareaFieldName(index),
                [`${x.editableField.name} is required`]
              );

              let state = {...this.state};
              if (!this.state.excludeKeys.includes(FieldNameHelper.getTextareaFieldName(index))) {
                state.excludeKeys.push(FieldNameHelper.getTextareaFieldName(index));
              }
              this.setState(state);
            }
            break;
          case CUSTOM_FIELD_CODES.RadioButton:
            if (!x.editableField.stringValue) {
              errors = Validations.setErrors(
                {...errors}, FieldNameHelper.getRadioButtonFieldName(index),
                [`${x.editableField.name} is required`]
              );

              let state = {...this.state};
              if (!this.state.excludeKeys.includes(FieldNameHelper.getRadioButtonFieldName(index))) {
                state.excludeKeys.push(FieldNameHelper.getRadioButtonFieldName(index));
              }
              this.setState(state);
            }
            break;
          case CUSTOM_FIELD_CODES.DropDownList:
            if (!x.editableField.stringValue || x.editableField.stringValue.trim().length < 1) {
              errors = Validations.setErrors(
                {...errors}, FieldNameHelper.getDropdownFieldName(index),
                [`${x.editableField.name} is required`]
              );

              let state = {...this.state};
              if (!this.state.excludeKeys.includes(FieldNameHelper.getDropdownFieldName(index))) {
                state.excludeKeys.push(FieldNameHelper.getDropdownFieldName(index));
              }
              this.setState(state);
            }
            break;
          case CUSTOM_FIELD_CODES.Checkbox:
            if (!x.editableField.boolValue) {
              errors = Validations.setErrors(
                {...errors}, FieldNameHelper.getCheckboxFieldName(index),
                [`${x.editableField.name} is required`]
              );

              let state = {...this.state};
              if (!this.state.excludeKeys.includes(FieldNameHelper.getCheckboxFieldName(index))) {
                state.excludeKeys.push(FieldNameHelper.getCheckboxFieldName(index));
              }
              this.setState(state);
            }
            break;
          case CUSTOM_FIELD_CODES.DatePicker:
            if (!x.editableField.dateTimeValue) {
              errors = Validations.setErrors(
                {...errors}, FieldNameHelper.getDatePickerFieldName(index),
                [`${x.editableField.name} is required`]
              );

              let state = {...this.state};
              if (!this.state.excludeKeys.includes(FieldNameHelper.getDatePickerFieldName(index))) {
                state.excludeKeys.push(FieldNameHelper.getDatePickerFieldName(index));
              }
              this.setState(state);
            }
            break;
          default:
            break;
        }
      }
    });

    return errors;
  }

  private static showErrorToastMessage() {
    toast.error(CHECK_ERROR_MESSAGE);
  }

  convertIEditableOrReadonlyFieldInfoToIEntryFieldWithIndexInfo(customFields: IEditableOrReadonlyFieldInfo[]) {
    if (customFields.length === 0) {
      return [];
    }

    let fields: IEntryFieldWithIndexInfo[] = [];

    customFields.forEach((x, index) => {
      if (x.editableField) {
        switch (x.editableField.fieldTypeCode) {
          case CUSTOM_FIELD_CODES.Text:
          case CUSTOM_FIELD_CODES.TextArea:
          case CUSTOM_FIELD_CODES.RadioButton:
            if (!x.editableField.isRequired) {
              fields.push({
                contestCustomFieldId: x.editableField.contestCustomFieldId,
                index: index,
                stringValue: x.editableField.stringValue === DEFAULT ? null : x.editableField.stringValue
              });
              break;
            } else {
              fields.push({
                contestCustomFieldId: x.editableField.contestCustomFieldId,
                index: index,
                stringValue: x.editableField.stringValue
              });
              break;
            }
          case CUSTOM_FIELD_CODES.DropDownList:
            fields.push({
              contestCustomFieldId: x.editableField.contestCustomFieldId,
              index: index,
              stringValue: x.editableField.stringValue
            });
            break;
          case CUSTOM_FIELD_CODES.Checkbox:
            fields.push({
              contestCustomFieldId: x.editableField.contestCustomFieldId,
              index: index,
              boolValue: x.editableField.boolValue
            });
            break;
          case CUSTOM_FIELD_CODES.DatePicker:
            fields.push({
              contestCustomFieldId: x.editableField.contestCustomFieldId,
              index: index,
              dateTimeValue: x.editableField.dateTimeValue
                ? moment(x.editableField.dateTimeValue).format(DATE_TIME_ISO_FORMAT)
                : null
            });
            break;
          default:
            break;
        }
      }
    });

    return fields;
  }

  convertICustomFieldDefinitionToIEntryFieldInfo(customFields: ICustomFieldDefinition[]) {
    if (customFields.length === 0) {
      return [];
    }

    let fields: IEntryFieldInfo[] = [];

    customFields.forEach(x => {
      switch (x.typeCode) {
        case CUSTOM_FIELD_CODES.Text:
        case CUSTOM_FIELD_CODES.TextArea:
        case CUSTOM_FIELD_CODES.RadioButton:
          if (!x.isRequired) {
            fields.push({
              contestCustomFieldId: x.id,
              stringValue: x.value === DEFAULT ? null : x.value
            });
            break;
          } else {
            fields.push({
              contestCustomFieldId: x.id,
              stringValue: x.value
            });
            break;
          }
        case CUSTOM_FIELD_CODES.DropDownList:
          fields.push({
            contestCustomFieldId: x.id,
            stringValue: x.value
          });
          break;
        case CUSTOM_FIELD_CODES.Checkbox:
          fields.push({
            contestCustomFieldId: x.id,
            boolValue: x.value
          });
          break;
        case CUSTOM_FIELD_CODES.DatePicker:
          fields.push({
            contestCustomFieldId: x.id,
            dateTimeValue: x.value ? moment(x.value).format(DATE_TIME_ISO_FORMAT) : null
          });
          break;
        default:
          break;
      }
    });

    return fields;
  }

  convertIEditableFieldInfoToICustomFieldDefinition(editableField: IEditableFieldInfo): ICustomFieldDefinition {
    if (editableField.fieldTypeCode === CUSTOM_FIELD_CODES.Text) {
      return {
        id: editableField.contestCustomFieldId,
        name: editableField.name,
        typeCode: editableField.fieldTypeCode,
        description: editableField.description,
        isRequired: editableField.isRequired,
        options: editableField.options,
        value: editableField.stringValue ? editableField.stringValue : ''
      };
    } else if (editableField.fieldTypeCode === CUSTOM_FIELD_CODES.TextArea) {
      return {
        id: editableField.contestCustomFieldId,
        name: editableField.name,
        typeCode: editableField.fieldTypeCode,
        description: editableField.description,
        isRequired: editableField.isRequired,
        options: editableField.options,
        value: editableField.stringValue ? editableField.stringValue : ''
      };
    } else if (editableField.fieldTypeCode === CUSTOM_FIELD_CODES.RadioButton) {
      return {
        id: editableField.contestCustomFieldId,
        name: editableField.name,
        typeCode: editableField.fieldTypeCode,
        description: editableField.description,
        isRequired: editableField.isRequired,
        options: editableField.options,
        value: editableField.stringValue ? editableField.stringValue : ''
      };
    } else if (editableField.fieldTypeCode === CUSTOM_FIELD_CODES.DropDownList) {
      return {
        id: editableField.contestCustomFieldId,
        name: editableField.name,
        typeCode: editableField.fieldTypeCode,
        description: editableField.description,
        isRequired: editableField.isRequired,
        options: editableField.options,
        value: editableField.stringValue ? editableField.stringValue : ''
      };
    } else if (editableField.fieldTypeCode === CUSTOM_FIELD_CODES.Checkbox) {
      return {
        id: editableField.contestCustomFieldId,
        name: editableField.name,
        typeCode: editableField.fieldTypeCode,
        description: editableField.description,
        isRequired: editableField.isRequired,
        options: editableField.options,
        value: editableField.boolValue,
      };
    } else
      return ({
        id: editableField.contestCustomFieldId,
        name: editableField.name,
        typeCode: editableField.fieldTypeCode,
        description: editableField.description,
        isRequired: editableField.isRequired,
        options: editableField.options,
        value: editableField.dateTimeValue
      });
  }

  addFieldForEditingToExcludeKeys(customFields: IEditableOrReadonlyFieldInfo[]) {
    if (customFields.length === 0) {
      return [];
    }

    let excludeKeys: string[] = [];

    customFields.forEach((x, index) => {
      if (x.editableField) {
        switch (x.editableField.fieldTypeCode) {
          case CUSTOM_FIELD_CODES.Text:
          case CUSTOM_FIELD_CODES.TextArea:
          case CUSTOM_FIELD_CODES.RadioButton:
          case CUSTOM_FIELD_CODES.DropDownList:
            excludeKeys.push(FieldNameHelper.getFieldValuesStringValueFieldName(index));
            break;
          case CUSTOM_FIELD_CODES.Checkbox:
            excludeKeys.push(FieldNameHelper.getFieldValuesBoolValueFieldName(index));
            break;
          case CUSTOM_FIELD_CODES.DatePicker:
            excludeKeys.push(FieldNameHelper.getFieldValuesDateTimeValueFieldName(index));
            break;
          default:
            break;
        }
      }
    });

    return excludeKeys;
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);

    if (this.state.validationErrors) {
      let render = Validations.getValidationSummary(this.state.validationErrors, this.state.excludeKeys);

      if (render) {
        toast.error(render);
      }
    }
  }

}

export default withVotingAppAuthorize(EditEntryAfterWin);