import {
  faCamera,
  faCheck,
  faDoorOpen,
  faEye,
  faHeart, faList,
  faPencilAlt,
  faPlus,
  faTrashAlt,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react';
import { Button } from 'react-bootstrap-v5';
import { IIconButtonProps } from './IIconButtonProps';
import { faVerticalAlign } from "../../../../styles/IconStyles";

export default class IconButton extends Component<IIconButtonProps> {

  constructor(props: Readonly<IIconButtonProps>) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  render() {
    return (
      <>
        <Button id={this.props.id} type={this.props.buttonType} variant={this.props.variant} onClick={this.onClickHandler}
                style={this.props.styles} className={this.props.className} disabled={this.props.disabled}
                size={this.props.size}>
          {
            (this.props.title !== undefined && this.props.title !== '' && this.props.title !== null)
              ?
              <>
                <span className='d-inline-block pr-2'>{this.renderIcon()}</span>
                <span className="align-baseline">
                  {
                    this.props.isLoading &&
                    <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"/>
                  }

                  {this.props.title}
                </span>
              </>
              :
              <>
                <span className='d-inline-block'>{this.renderIcon()}</span>
              </>
          }
        </Button>
      </>
    );
  }

  renderIcon() {
    switch (this.props.iconType) {
      case 'submit':
        return <FontAwesomeIcon icon={faCheck} style={faVerticalAlign}/>;
      case 'add':
        return <FontAwesomeIcon icon={faPlus} style={faVerticalAlign}/>;
      case 'edit':
        return <FontAwesomeIcon icon={faPencilAlt} style={faVerticalAlign}/>;
      case 'delete':
        return <FontAwesomeIcon icon={faTrashAlt} style={faVerticalAlign}/>;
      case 'like':
        return <FontAwesomeIcon icon={faHeart} style={faVerticalAlign}/>;
      case 'eye':
        return <FontAwesomeIcon icon={faEye} style={faVerticalAlign}/>;
      case 'camera':
        return <FontAwesomeIcon icon={faCamera} style={faVerticalAlign}/>;
      case 'user':
        return <FontAwesomeIcon icon={faUserCircle} style={faVerticalAlign}/>;
      case 'door':
        return <FontAwesomeIcon icon={faDoorOpen} style={faVerticalAlign}/>;
      case 'list':
        return <FontAwesomeIcon icon={faList} style={faVerticalAlign}/>;
      default:
        return <> </>;
    }
  }

  private onClickHandler() {
    if (this.props.onClick !== undefined) {
      this.props.onClick(this.props.value);
    }
  }
}
