import { faCopy, faHeart, faSearch, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import { Button, Card, Image } from "react-bootstrap-v5";
import 'react-image-lightbox/style.css';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import { IEntryTileInfo } from "../../api/api-interfaces/entry/IEntryTileInfo";
import {
  CUSTOM_FIELD_CODES,
  KIWANIS_BLUE_COLOR,
  KIWANIS_GOLD_COLOR,
  MOMENT_DATE_TIME_FORMAT
} from "../../constants/Constants";
import AuthHelper from "../../helpers/auth-helper/AuthHelper";
import RoutingConstants from "../../routes/RoutingConstants";
import imageStyles from "../../styles/ImageBigStyles.module.scss";
import IconButton from "../common/buttons/icon-button/IconButton";
import CustomLink from "../common/custom-link/CustomLink";
import NotProvided from "../common/not-provided-value/NotProvided";
import styles from './EntryCard.module.scss';
import { IEntryCardProps } from "./IEntryCardProps";
import { IEntryCardState } from "./IEntryCardState";
import { faVerticalAlign } from "../../styles/IconStyles";

class EntryCard extends Component<IEntryCardProps, IEntryCardState> {

  render() {
    let {entry} = this.props;

    return (
      <Card className='shadow'>
        <Card.Header>
          <div className='text-truncate fw-bold' style={{color: KIWANIS_GOLD_COLOR}}>
            <CustomLink
              to={RoutingConstants.buildEntryDetailsUrl(this.props.contestId, this.props.page, entry.id, this.props.sorting)}
              styles={{textDecoration: 'none'}}
            >
              {
                entry.isWinner &&
                <span className='me-2'><FontAwesomeIcon icon={faTrophy} style={faVerticalAlign}/></span>
              }
              <span title={entry.title}>{entry.title}</span>
            </CustomLink>
          </div>
        </Card.Header>

        <div className={imageStyles.vaContainer}>
          <Image src={entry.defaultImageUrl} alt={`entry_image_${entry.id}`} className={imageStyles.vaImage} fluid
                 onClick={this.onImageGalleryOpenClick.bind(this)}
          />
          <div className={imageStyles.vaOverlay} onClick={this.onImageGalleryOpenClick.bind(this)}>
            <div className={imageStyles.vaText}>
              <FontAwesomeIcon size={"3x"} icon={faSearch} color={KIWANIS_GOLD_COLOR} style={faVerticalAlign}/>
            </div>
          </div>
        </div>

        <Card.Body>
          <div className={styles.cursorPointer}
               onClick={() => this.onEntryDetailsClick(RoutingConstants.buildEntryDetailsUrl(this.props.contestId, this.props.page, entry.id, this.props.sorting))}>
            {
              entry.shouldShowDescription
                ? <div className={styles.textBlock} title={entry.description}>{entry.description}</div>
                : null
            }

            {
              entry.fields && entry.fields.length > 0 &&
              entry.fields.map((field, index) => {
                switch (field.fieldTypeCode) {
                  case CUSTOM_FIELD_CODES.Text:
                    return (
                      <div key={index} className='my-3'>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.name}>
                          <b>{field.name}</b>
                        </div>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.stringValue}>
                          {field.stringValue ? field.stringValue : <NotProvided/>}
                        </div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.TextArea:
                    return (
                      <div key={index} className='my-3'>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.name}>
                          <b>{field.name}</b>
                        </div>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.stringValue}>
                          {
                            field.stringValue
                              ? <span className={styles.textAreaBlock}>{field.stringValue}</span>
                              : <span className={styles.textAreaBlock}><NotProvided/></span>
                          }
                        </div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.RadioButton:
                    return (
                      <div key={index} className='my-3'>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.name}>
                          <b>{field.name}</b>
                        </div>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.stringValue}>
                          {field.stringValue ? field.stringValue : <NotProvided/>}
                        </div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.DropDownList:
                    return (
                      <div key={index} className='my-3'>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.name}>
                          <b>{field.name}</b>
                        </div>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.stringValue}>
                          {field.stringValue ? field.stringValue : <NotProvided/>}
                        </div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.Checkbox:
                    return (
                      <div key={index} className='my-3'>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.name}>
                          <b>{field.name}</b>
                        </div>
                        <div className={`text-truncate ${styles.cfLineHeight}`}>
                          {
                            field.boolValue === null
                              ? <NotProvided/>
                              : field.boolValue ? 'Yes' : 'No'
                          }
                        </div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.DatePicker:
                    return (
                      <div key={index} className='my-3'>
                        <div className={`text-truncate ${styles.cfLineHeight}`} title={field.name}>
                          <b>{field.name}</b>
                        </div>
                        <div className={`text-truncate ${styles.cfLineHeight}`}>
                          {
                            field.dateTimeValue
                              ? moment(field.dateTimeValue).format(MOMENT_DATE_TIME_FORMAT)
                              : <NotProvided/>
                          }
                        </div>
                      </div>
                    );
                  default:
                    return (
                      <div>
                        <span>Invalid field</span>
                      </div>
                    );
                }
              })
            }
          </div>
          <div className={`${styles.buttonWrapper} text-center`}>
            <Button className={`${styles.clipboardButton} mx-1`}
                    onClick={() => this.copyCodeToClipboard(entry.entryDetailsUrl)}>
              <FontAwesomeIcon icon={faCopy} style={faVerticalAlign}/>
            </Button>
            <FacebookShareButton url={entry.entryDetailsUrl} title={entry.title} quote={entry.title} className="share">
              <FacebookIcon size={32} round={true} className='mx-1'/>
            </FacebookShareButton>
            <TwitterShareButton url={entry.entryDetailsUrl} title={entry.title}>
              <TwitterIcon size={32} round={true} className='mx-1'/>
            </TwitterShareButton>
          </div>
        </Card.Body>
        <Card.Footer>
          <div className="d-grid gap-2 mb-1 text-center">
            {entry.viewCount} {entry.viewCount === 1 ? 'view' : 'views'}
          </div>

          {
            this.props.isVotingStarted &&
            <div className="d-grid gap-2 text-center">
              {
                this.renderOnlyVotesOrButton(entry)
              }
            </div>
          }
        </Card.Footer>
      </Card>
    );
  }

  renderOnlyVotesOrButton(entry: IEntryTileInfo): ReactNode {
    let dateNow = new Date();
    if (this.props.isVotingEnded
      || !this.props.isVotingOpen
      || (this.props.votingEndTime && this.props.votingEndTime < dateNow)
    ) {
      return this.props.hideVoteCount ? null : this.renderOnlyVotes(entry);
    } else {
      if (!AuthHelper.isTokenSet() || entry.canUserVoteOnThis) {
        return this.renderButton(entry);
      } else {
        return this.renderOnlyVotes(entry);
      }
    }
  }

  renderOnlyVotes(entry: IEntryTileInfo) {
    return (
      <Button id={this.props.buttonScrollId} type='button' className='notActiveButton'>
        <FontAwesomeIcon icon={faHeart} color={KIWANIS_BLUE_COLOR} style={faVerticalAlign}/>
        {this.props.hideVoteCount ? '' : ` ${entry.voteCount}`}
      </Button>
    );
  }

  renderButton(entry: IEntryTileInfo) {
    return (
      <IconButton id={this.props.buttonScrollId} variant="primary"
                  iconType={"like"}
                  title={this.props.hideVoteCount ? '' : entry.showAnimation ? '' : ` ${entry.voteCount}`}
                  onClick={() => this.onVoteClick(entry.showAnimation)}
                  className={entry.showAnimation ? 'elVoteButton' : ''}
      />
    );
  }

  private onEntryDetailsClick(redirectUrl: string) {
    if (this.props.onEntryDetailsClick) {
      this.props.onEntryDetailsClick(redirectUrl);
    }
  }

  private copyCodeToClipboard(entryDetailsUrl: string) {
    if (this.props.onCopyCodeToClipboardClick) {
      this.props.onCopyCodeToClipboardClick(entryDetailsUrl);
    }
  }

  private onImageGalleryOpenClick() {
    if (this.props.onImageGalleryOpenClick) {
      this.props.onImageGalleryOpenClick(this.props.entry.imageUrls);
    }
  }

  private onVoteClick(showAnimation: boolean) {
    if (this.props.onVoteClick) {
      if (!showAnimation) {
        this.props.onVoteClick();
      }
    }
  }
}

export default EntryCard;