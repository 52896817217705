import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import withVotingAppAuthorize from "../../components/common/authorize/withVotingAppAuthorize";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";
import LoginComponent from "../../components/common/login-component/LoginComponent";
import RoutingConstants from "../../routes/RoutingConstants";
import { ILoginProps } from "./ILoginProps";
import { ILoginState } from "./ILoginState";

class LoginPage extends Component<ILoginProps, ILoginState> {
  _isMounted = false;

  constructor(props: ILoginProps) {
    super(props);

    const url = props.returnUrl;
    const redirectUrl = !url ? RoutingConstants.HOME : url;

    this.state = {
      redirect: null,
      redirectUrl: redirectUrl
    };
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    if (this.props.auth.isAuthenticated) {
      return <Redirect push to={this.state.redirectUrl}/>;
    }

    return (
      <ContainerPageSize>
        <LoginComponent onLoginSuccess={() => this.onLoginSuccess()}/>
      </ContainerPageSize>
    );
  }

  async componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onLoginSuccess() {
    if (this._isMounted) {
      this.setState({redirect: this.state.redirectUrl});
    }
  }
}

export default withVotingAppAuthorize(LoginPage);