import React, { ReactNode } from "react";
import ApiCommunicationError from "../../api/api-interfaces/errors/ApiCommunicationError";
import BadRequestError from "../../api/api-interfaces/errors/BadRequestError";
import NotFoundError from "../../api/api-interfaces/errors/NotFoundError";
import UnauthorizedError from "../../api/api-interfaces/errors/UnauthorizedError";
import ValidationErrors from "./ValidationErrors";

export default class Validations {
  public static addError(errors: ValidationErrors, key: string, message: string): ValidationErrors {
    if (errors[key] === undefined) {
      errors[key] = [];
    }

    errors[key].push(message);

    return errors;
  }

  public static setErrors(errors: ValidationErrors, key: string, messages: string[]): ValidationErrors {
    errors[key] = messages;
    return errors;
  }

  public static deleteErrors(errors: ValidationErrors, key: string): ValidationErrors {
    if (errors[key]) {
      delete errors[key];
      return errors;
    }
    return errors;
  }

  public static buildApiCommunicationErrors(mainMessage: string, err: any): ValidationErrors {
    console.error(mainMessage, err);

    if (err instanceof BadRequestError) {
      return err.validationErrors;
    } else if (err instanceof ApiCommunicationError) {
      return Validations.setErrors({}, '*', [mainMessage + ' - API error']);
    } else if (err instanceof UnauthorizedError) {
      return Validations.setErrors({}, '*', [mainMessage + ' - access denied']);
    } else if (err instanceof NotFoundError) {
      return Validations.setErrors({}, '*', [mainMessage + ' - URL not found']);
    }
    return Validations.setErrors({}, '*', [mainMessage]);
  }

  public static getValidationSummary(validationErrors: ValidationErrors, excludedKeys: string[]): ReactNode | null {
    if (validationErrors === undefined || validationErrors === null) {
      return null;
    }

    let matchingMessages: string[] = [];

    for (const field in validationErrors) {
      if (Object.prototype.hasOwnProperty.call(validationErrors, field)) {
        if (excludedKeys === undefined || !excludedKeys.includes(field)) {
          const fieldMessages = validationErrors[field];
          matchingMessages = matchingMessages.concat(fieldMessages);
        }
      }
    }

    if (matchingMessages === undefined || matchingMessages.length === 0) {
      return null;
    }

    return (
        <div>
          <ul className='m-auto'>
            {matchingMessages.map((x, i) => <li key={i}>{x}</li>)}
          </ul>
        </div>
    );
  }
}