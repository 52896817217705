import ValidationErrors from "../validation-helper/ValidationErrors";

export const scrollIntoViewHelper = (errors: ValidationErrors) => {
  const PHOTOS: string = 'Photos'
  const firstError = Object.keys(errors)[0];

  let el;
  if (firstError === PHOTOS) {
    el = document.getElementById(PHOTOS);
  } else {
    el = document.querySelector(`[name="${firstError}"]`);
  }

  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};