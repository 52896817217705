import React from 'react';
import { Col, Row } from "react-bootstrap-v5";
import { useHistory } from "react-router-dom";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import ContainerPageSize from "../../components/common/container-page-size/ContainerPageSize";

const NotFoundPage = () => {
  let history = useHistory();

  const goToPreviousPath = () => {
    history.goBack();
  };

  return (
    <ContainerPageSize>
      <div className="text-center" style={{marginTop: '20vh'}}>
        <Row>
          <Col md={{span: 8, offset: 2}}>
            <h1 className={"headline"}>404 - Page not found</h1>
            <IconButton variant="outline-primary" title="Back to site" onClick={goToPreviousPath}/>
          </Col>
        </Row>
      </div>
    </ContainerPageSize>
  );
};



export default NotFoundPage;